import { Injectable } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class SeoService {
  private defaultTitle = 'Remate Agroshop';
  private defaultDescription = 'Leve mais produtividade para sua propriedade rural. Escolha tratores, implementos e peças, novos ou seminovos, de qualidade com a AGROSHOP. Conheça nosso catálogo.';
  private defaultKeywords = 'tratores, implementos, peças, novos, seminovos, qualidade, agroshop, catálogo, propriedades rurais, maquinário agrícola, colhedeiras, colhedouras, colheitadeiras';
  private maxDescriptionLength = 160;

  constructor(private titleService: Title, private metaService: Meta) {}

  private stripHtml(html: string): string {
    const div = document.createElement('div');
    div.innerHTML = html;
    return div.textContent || div.innerText || '';
  }

  setTitle(title: string = this.defaultTitle): void {
    this.titleService.setTitle(title);
  }

  setDescription(description: string = this.defaultDescription): void {
    description = this.stripHtml(description);
    if (description.length > this.maxDescriptionLength) {
      const words = description.split(' ');
      let truncatedDescription = '';
      for (const word of words) {
        if ((truncatedDescription + word).length > this.maxDescriptionLength) break;
        truncatedDescription += (truncatedDescription ? ' ' : '') + word;
      }
      description = truncatedDescription;
    }
    this.metaService.updateTag({ name: 'description', content: description });
  }

  setKeywords(keywords: string = this.defaultKeywords): void {
    this.metaService.updateTag({ name: 'keywords', content: keywords });
  }

  setOgTitle(ogTitle: string = this.defaultTitle): void {
    this.metaService.updateTag({ property: 'og:title', content: ogTitle });
  }

  setOgImage(ogImage: string): void {
    const host = window.location.origin;
    const absoluteImageUrl = ogImage.startsWith('http') ? ogImage : `${host}${ogImage}`;
    this.metaService.updateTag({ property: 'og:image', content: absoluteImageUrl }, 'property="og:image"');
  }

  setOgDescription(ogDescription: string = this.defaultDescription): void {
    ogDescription = this.stripHtml(ogDescription);
    if (ogDescription.length > this.maxDescriptionLength) {
      const words = ogDescription.split(' ');
      let truncatedDescription = '';
      for (const word of words) {
        if ((truncatedDescription + word).length > this.maxDescriptionLength) break;
        truncatedDescription += (truncatedDescription ? ' ' : '') + word;
      }
      ogDescription = truncatedDescription;
    }
    this.metaService.updateTag({ property: 'og:description', content: ogDescription });
  }

  setOgUrl(ogUrl: string = window.location.href): void {
    this.metaService.updateTag({ property: 'og:url', content: ogUrl });
  }

  setDefaultTags(): void {
    this.setTitle();
    this.setDescription();
    this.setKeywords();
    this.setOgTitle();
    this.setOgDescription();
    this.setOgUrl();
    this.setOgImage('/assets/logos/logo_original.png')
    // Set default values for og:image if needed
  }
}