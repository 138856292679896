import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EventProvider } from 'src/app/core/data/event/event.provider';
import { Event } from 'src/app/core/data/event/event.model';
import { Product } from 'src/app/core/data/product/product.model';
import { SegmentProvider } from 'src/app/core/data/segment/segment.provider';
import { Segment } from 'src/app/core/data/segment/segment.model';
import { environment, region_phrases, RegionNames } from 'src/environments/environment';
import { SeoService } from 'src/app/core/services/seo.service';

type SortOptions = 'high-price' | 'low-price' | '';

@Component({
  selector: 'app-event-list',
  templateUrl: './event-list.component.html',
  styleUrls: ['./event-list.component.scss'],
})
export class EventListCategoryComponent implements OnInit {
  public events: Event[];
  public segment: Segment;
  public lots: Product[];
  public sort: SortOptions = '';

  public translations = region_phrases[environment.region_id as keyof RegionNames];

  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly eventProvider: EventProvider,
    private readonly segmentProvider: SegmentProvider,
    private readonly seoService: SeoService
  ) { }

  ngOnInit(): void {
    this.seoService.setDefaultTags();
    this.seoService.setTitle('Eventos - Remate Agroshop');
    
    this.route.params.subscribe((params) => {
      this.eventProvider
        .all({ categoryId: params.id })
        .subscribe((events) => {
          this.events = events;
          // setTimeout(() => this.clampText(), 50);
        });
      this.segmentProvider.info(params.id).subscribe((segment) => {
        this.segment = segment;
        // setTimeout(() => this.clampText(), 50);
      });
    });
  }

  // ngAfterViewInit() {
  //   this.clampText();
  // }

  // @HostListener('window:resize', ['$event'])
  // onResize() {
  //   this.clampText();
  // }

  // private clampText() {
  //   console.log('clamping text...');
  //   const elements = document.querySelectorAll('.event-description span');
  //   console.log({ elements })
  //   elements.forEach((el: any) => {
  //     const isOverflowing = el.scrollHeight > el.offsetHeight;
  //     console.log({ isOverflowing, el })
  //     if (isOverflowing) {
  //       // Initially hide the overflow indicator
  //       el.style.setProperty('--overflow-indicator', 'none');
  //       let text = el.innerText;
  //       while (el.scrollHeight > el.offsetHeight && text.length > 0) {
  //         text = text.substring(0, text.length - 1);
  //         el.innerText = text + '...';
  //       }
  //     }
  //   });
  // }
}
